/* eslint-disable no-undef */
// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastLogin.css?raw';

export default class SupercastLogin extends HTMLElement {
  connectedCallback() {
    this.subdomain = this.attributes.subdomain && this.attributes.subdomain.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    this.updateContent();
  }

  async updateContent() {
    const shadow = this.attachShadow({mode: 'open'});
    this.attachStyles(shadow);

    const cardContent = await this.fetchContent();

    shadow.appendChild(cardContent);

    await this.setupEmailEntryForm();
  }

  async fetchContent() {
    const template = document.createElement('template')
    template.innerHTML = `<div class='modal-container'>
      <div class='overlay'>
        <div class='modal'>
        </div>
      </div>
    </div>`
    return template.content.cloneNode(true);
  }

  async attachStyles() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    this.shadowRoot.appendChild(styleEl);
    return styleEl;
  }

  async input(e) {
    if (e.target.value) {
        const inputs = this.shadowRoot.querySelectorAll('.code > input');
        const index = inputs.indexOf(e.target);
        if (index < inputs.length - 1) {
            inputs[index+1].focus();
        }
        await this.login();
    }
  }

  async paste(e) {
    e.preventDefault();

    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const pattern = new RegExp("\\s*[0-9]{6}\\s*");
    if (pattern.test(paste)) {
      const numbers = paste.split("");
      const inputs = this.shadowRoot.querySelectorAll('.code > input');
      for (let i = 0; i < 6; i++) {
        inputs[i].value = numbers[i];
      }
      await this.login();
    }
  }

  back(e) {
    if (e.keyCode !== 8) return;
    if (e.target.value === "") {
        const inputs = this.shadowRoot.querySelectorAll('.code > input');
        const index = inputs.indexOf(e.target);
        if (index > 0) {
            const previous = inputs[index-1];
            previous.value = "";
            previous.focus();
        }
    }
  }

  async setupEmailEntryForm() {
    const modal = this.shadowRoot.querySelector('.modal');
    modal.innerHTML = `
      <h2>Sign in</h2>
      <form id='login-form' class='sc-form'>
        <div class='error' id='error'></div>
        <div class='field'>
          <input class='sc-input string' id="email" type='text' name='email' placeholder='Email' />
        </div>
        <div>
          <button type='button' class='button positive' id='login-button'>Sign In With Email</button>
        </div>
        <div style='margin-top: 15px;'>
          <em>Trouble logging in? <a href='https://support.supercast.com' target="_blank">Contact support</a>.</em>
        </div>
      </form>
    `;

    const overlay = this.shadowRoot.querySelector('.overlay')
    overlay.addEventListener('click', (event) => {
      // Only consider direct events
      if (event.target == overlay) {
        this.close();
      }
    });

    modal.querySelector('#login-form').addEventListener('submit', this.requestCode.bind(this));
    modal.querySelector('#login-button').addEventListener('click', this.requestCode.bind(this));
  }

  async setupCodeEntryForm(sent_to) {
    const modal = this.shadowRoot.querySelector('.modal');
    modal.innerHTML = `
      <button id="back" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
      </button>
      <h2>Check your email</h2>
      <p>We've sent a 6-digit code to ${sent_to}.<br />The code expires in 10 minutes, so please enter it soon.</p>
      <form id='code-form' class='sc-form'>
        <input id="email" type='hidden' name='email' value='${sent_to}' />

        <div class="code">
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
            <input pattern="[0-9]*" type="text" minlength="1" maxlength="1" />
        </div>
       
        <div class='error' id='error'></div>
      </form>
      <p>Can’t find your code? Check your spam folder!</p>
    `;
    this.shadowRoot.querySelectorAll('.code > input').forEach((el) => {
        el.addEventListener('paste', this.paste.bind(this));
        el.addEventListener('input', this.input.bind(this));
        el.addEventListener('keydown', this.back.bind(this));
    });
    this.shadowRoot.querySelector("#back").addEventListener('click', async (e) => {
        e.preventDefault();
        await this.setupEmailEntryForm();
    });
  }

  async requestCode(e) {
    e.preventDefault();
    const form = this.shadowRoot.querySelector('#login-form');
    const { success, sent_to } = await Supercast.requestCode({ subdomain: this.subdomain, environment: this.environment, email: form.elements["email"].value });

    if (success) {
       await this.setupCodeEntryForm(sent_to);
    } else {
        // This shouldn't really ever happen.
        this.shadowRoot.querySelector("#error").textContent = "Failed to request code.";
    }
  }

  async login() {
    const form = this.shadowRoot.querySelector('#code-form');
    const code = this.shadowRoot.querySelectorAll('.code > input').map(el => el.value).join("");
    if (code.length != 6) return;

    const { success, error } = await Supercast.login({ subdomain: this.subdomain, environment: this.environment, email: form.elements["email"].value, code: code });

    if (success) {
      this.close();
    } else {
      this.shadowRoot.querySelector('#error').textContent = error;
      if (error === "Sorry, you are not subscribed to this podcast.") {
        this.shadowRoot.querySelector('.code').style.display = "none";
      }
    }
  }

  close() {
    this.parentNode.removeChild(this);
  }
}
