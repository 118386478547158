// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastEntitySearch.css?raw';
import { domain } from 'utils/environment';
import Base from 'elements/base';
import { debounce } from 'debounce';

export default class SupercastEntitySearch extends Base {
  attributeConfig = {
    environment: { required: false, default: 'production' }
  }

  async fetchCard() {
    const url = new URL(`${domain('app', this.environment)}/embeds/entities`);
    const response = await fetch(url);
    if (response.ok) {
      const html = await response.text();
      return this.extractCard(html);
    } else {
      this.showError("Unable to render entity search");
    }
  }

  async fetchResults() {
    const url = new URL(`${domain('app', this.environment)}/embeds/entities/results`);
    url.searchParams.set('search', this.searchValue);
    const response = await fetch(url);
    if (response.ok) {
      const html = await response.text();
      return this.extractCard(html);
    } else {
      this.showResultsError("Unable to show search results");
    }
  }

  async fetchStyle() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    return styleEl;
  }

  extractCard(html) {
    const holderEl = document.createElement('div');
    holderEl.innerHTML = html;
    return holderEl.querySelector('.embed-card-content');
  }

  async refreshContent(shadow) {
    const cardContent = await this.fetchResults();
    if (cardContent.querySelector('li')) {
      // If we can find a Li, we have a result
      shadow.querySelector("input[type='text']").classList.add('with-results');
    } else {
      shadow.querySelector("input[type='text']").classList.remove('with-results');
    }
    
    shadow.querySelector(".results-container").innerHTML = cardContent.outerHTML;
  }

  showResultsError(message) {
    this.rootElement.querySelector(".results-container").innerHTML = `
      <div style="color: red;">${message}</div>
    `
  }

  search(searchValue) {
    if (searchValue.length > 2) {
      this.searchValue = searchValue;
      this.refreshContent(this.rootElement);
    }
  }

  attachEvents(shadow) {
    this.search = debounce(this.search, 200);

    shadow.addEventListener('input', (e) => {
      if (!e.target.classList.contains('entity-search')) {
        return;
      }

      this.search.call(this, e.target.value);
    });
  }
}
