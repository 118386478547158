.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(79, 72, 72, 0.9) 0%, rgba(123, 123, 123, 0.9) 50%, rgba(225, 225, 225, 0.9) 100%);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes dropIn {
  from {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.modal {
  box-sizing: border-box;
  position: relative;
  border-radius: 16px;
  padding: 32px 48px 48px;
  overflow-y: auto;
  max-height: calc(100vh - 10vw);
  max-width: 90vw;
  opacity: 0;
  background-color: white;
  animation: dropIn 0.3s ease-out forwards;
  text-align: center;
}
@media (max-width: 500px) {
  .modal {
    padding: 32px 24px;
  }
}

.sc-form .field + .fields,
.sc-form .field-group + .field,
.sc-form .field + .field,
.sc-form .checkbox + .checkbox,
.sc-form .radio + .radio {
  margin-top: 16px;
}
.sc-form .field-group {
  align-items: baseline;
  display: flex;
  position: relative;
  gap: 5px;
}
.sc-form .field-group .field {
  flex: 1 0 0;
}

.sc-input[type=text],
.sc-input[type=number],
.sc-input[type=email],
.sc-input[type=url],
.sc-input[type=tel],
.sc-input[type=password],
textarea.sc-input {
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #dde2e6;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  line-height: 16px;
  padding: 11px 12px 9px;
  width: 100%;
  transition: 0.2s all ease-out;
  box-sizing: border-box;
}
.sc-input[type=text]:hover,
.sc-input[type=number]:hover,
.sc-input[type=email]:hover,
.sc-input[type=url]:hover,
.sc-input[type=tel]:hover,
.sc-input[type=password]:hover,
textarea.sc-input:hover {
  border-color: #c0c9d0;
}
.sc-input[type=text]:focus,
.sc-input[type=number]:focus,
.sc-input[type=email]:focus,
.sc-input[type=url]:focus,
.sc-input[type=tel]:focus,
.sc-input[type=password]:focus,
textarea.sc-input:focus {
  border-color: #333a41;
  box-shadow: inset 0 0 0 1px #333a41;
  outline: none;
}

.button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  padding: 15px 20px 13px;
  text-decoration: none;
  transition: background-color 0.07s linear;
  color: #FFF;
  font-weight: 600;
  background-color: var(--supercast-button-background-color, #3d62b3);
  margin-top: 16px;
  width: 100%;
}

a {
  color: var(--supercast-button-background-color, #3d62b3);
  font-weight: bold;
}

.error {
  margin-bottom: 15px;
  color: red;
}

em {
  font-size: 0.8em;
  color: #6f7479;
}

#login-form {
  min-width: 20rem;
}

#back {
  position: absolute;
  background: none;
  border: 0;
  padding: 0.5rem;
  top: 1em;
  left: 1em;
  cursor: pointer;
}

#back svg {
  width: 1.5em;
}

.code {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.code > input {
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 0.5rem;
  width: 1.75rem;
  padding: 0.5rem 0.75rem;
  margin: 0;
  border: 1px solid #939393;
}

/*# sourceMappingURL=SupercastLogin.css.map */
