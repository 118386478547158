/* eslint-disable no-undef */
// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastEmbed.css?raw';
import { domain } from 'utils/environment';

export default class SupercastEmbed extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.planId = parseInt(this.attributes.plan_id && this.attributes.plan_id.value, 10);
    this.code = this.attributes.code && this.attributes.code.value;
    this.subdomain = this.attributes.subdomain && this.attributes.subdomain.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    this.updateContent();
  }

  async updateContent() {
    const [cardContent, css] = await Promise.all([this.fetchCard(), this.fetchStyle()]);

    const shadow = this.attachShadow({mode: 'closed'});
    shadow.appendChild(css);
    shadow.appendChild(cardContent);

    this.attachEvents(shadow);
  }

  async fetchCard() {
    const url = new URL(`${domain(this.subdomain, this.environment)}/subscriptions/card`);
    if (this.planId > 0) {
      console.warn('Supercast warning - Loading plans by plan / subdomain is deprecated. We recommend loading your plans using an embed code. You can find your code by clicking "Embed this plan" from your dashboard.');
      url.searchParams.set('subscription[plan_id]', this.planId);
    } else {
      url.searchParams.set('code', this.code);
    }
    const response = await fetch(url);
    if (response.ok) {
      const html = await response.text();
      return this.extractCard(html);
    } else {
      const error = document.createElement('div');
      error.style.color = "red";
      error.innerText = "We were unable to find your plan. Please confirm you are using a valid code to load your plan. You can find the correct code in 'Embed your plan' in your dashboard."
      return error;
    }
  }

  async fetchStyle() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    return styleEl;
  }

  extractCard(html) {
    const holderEl = document.createElement('div');
    holderEl.innerHTML = html;
    return holderEl.querySelector('.embed-card-content');
  }

  attachEvents(shadow) {
    shadow.querySelector('.button').addEventListener('click', (e) => {
      e.preventDefault();
      Supercast.startPurchase({
        code: this.code,
        subdomain: this.subdomain,
        planId: this.planId,
        environment: this.environment
      })
    })
  }
}
