.embed-content {
  background: #FFF;
}

.justify-center {
  justify-content: center;
}

.flex {
  display: -webkit-box;
  display: flex;
}

.w-2\/3 {
  width: 66.666667%;
}

.text-left {
  text-align: left;
}

.goal-amount {
  color: #848e97;
  font-size: 16px;
}

.goal-amount .goal-amount-counts {
  color: black;
  font-weight: bold;
}

.goal {
  display: block;
  width: 100%;
  height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*# sourceMappingURL=SupercastGoal.css.map */
