/* eslint-disable no-undef */
// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastEmbed.css?raw';
import { domain } from 'utils/environment';

export default class SupercastPlan extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.planId = parseInt(this.attributes.plan_id && this.attributes.plan_id.value, 10);
    this.interval = this.attributes.interval ? this.attributes.interval.value : null;
    this.code = this.attributes.code && this.attributes.code.value;
    this.subdomain = this.attributes.subdomain && this.attributes.subdomain.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    this.updateContent();
  }

  async updateContent() {
    const [cardContent, css] = await Promise.all([this.fetchCard(), this.fetchStyle()]);

    const shadow = this.attachShadow({mode: 'open'});
    shadow.appendChild(css);
    shadow.appendChild(cardContent);

    this.attachEvents(shadow);
  }

  async fetchCard() {
    const query = new URLSearchParams(window.location.search);
    const url = new URL(`${domain(this.subdomain, this.environment)}/subscriptions/card`);
    if (this.planId > 0) {
      console.warn('Supercast warning - Loading plans by plan / subdomain is deprecated. We recommend loading your plans using an embed code. You can find your code by clicking "Embed this plan" from your dashboard.');
      url.searchParams.set('subscription[plan_id]', this.planId);
    } else {
      url.searchParams.set('code', this.code);
    }

    if (this.interval) {
      url.searchParams.set('interval', this.interval);
    }

    // Pass through all utm properties
    if (query.has("utm_source")) {
        url.searchParams.set('utm_source', query.get('utm_source'));
    }
    if (query.has("utm_medium")) {
        url.searchParams.set('utm_medium', query.get('utm_medium'));
    }
    if (query.has("utm_term")) {
        url.searchParams.set('utm_term', query.get('utm_term'));
    }
    if (query.has("utm_content")) {
        url.searchParams.set('utm_content', query.get('utm_content'));
    }
    if (query.has("utm_campaign")) {
        url.searchParams.set('utm_campaign', query.get('utm_campaign'));
    }
    if (document.referrer) {
        url.searchParams.set('sc_ref', document.referrer);
    }

    const response = await fetch(url.toString());
    if (response.ok) {
      const html = await response.text();
      return this.extractCard(html);
    } else {
      const error = document.createElement('div');
      error.style.color = "red";
      error.innerText = "We were unable to find your plan. Please confirm you are using a valid code to load your plan. You can find the correct code in 'Embed your plan' in your dashboard."
      return error;
    }
  }

  async fetchStyle() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    return styleEl;
  }

  extractCard(html) {
    const holderEl = document.createElement('div');
    holderEl.innerHTML = html;
    const card = holderEl.querySelector('.embed-card-content');

    let slotIndex = 0;

    // Extract anything with a stimulus controller to a slot so Stimulus can pick it up
    card.querySelectorAll("[data-controller='price-component']").forEach(component => {
      const slot = document.createElement('slot');
      slot.name = "slot-" + slotIndex;
      component.slot = "slot-" + slotIndex;
      slotIndex += 1;
      component.parentNode.replaceChild(slot, component);
      this.appendChild(component);
    });

    return card;
  }

  attachEvents(shadow) {
    if (!shadow.querySelector('.button')) {
      return;
    }

    shadow.querySelector('.button').addEventListener('click', (e) => {
      e.preventDefault();
      Supercast.startPurchase({
        code: this.code,
        subdomain: this.subdomain,
        planId: this.planId,
        environment: this.environment
      })
    })
  }
}
