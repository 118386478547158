/* eslint-disable no-undef */
// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastPlayerLinks.css?raw';
import { domain } from 'utils/environment';
import generateQRCode from 'utils/qr_code';

export default class SupercastPlayerLinks extends HTMLElement {
  async connectedCallback() {
    this.subscriptionIdentifier = this.attributes['subscription-identifier'] && this.attributes['subscription-identifier'].value;
    this.subdomain = this.attributes.subdomain && this.attributes.subdomain.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    this.shadow = this.attachShadow({mode: 'open'});

    if (!this.subscriptionIdentifier) {
      await this.detectSubscriptionIdentifier();
    } else {
      await this.updateContent();
    }
  }

  async detectSubscriptionIdentifier() {
    if (window.MemberStack) {
      // Try to retrieve the member from memberstack and use that
      try {
        const member = await MemberStack.onReady;
        this.subscriptionIdentifier = member.id;
        this.updateContent();
      } catch {
        this.showError("Error: Could not locate a MemberStack membership. Please ensure that the user is signed up.");
      }
    } else {
      this.updateContent();
    }
  }

  async updateContent() {
    const [content, css] = await Promise.all([this.fetchContent(), this.fetchStyle()]);
    this.shadow.innerHTML = '';

    this.shadow.appendChild(css);
    this.shadow.appendChild(content);

    if (this.success) {
      this.attachEvents(this.shadow);
    }
  }

  async fetchContent() {
    const url = new URL(`${domain(this.subdomain, this.environment)}/embeds/player_links`);
    if (Supercast.currentFeedToken) {
      url.searchParams.set("key", Supercast.currentFeedToken);
    }
    const headers = {};
    if (this.subscriptionIdentifier) {
      headers['X-Supercast-Subscription-Identifier'] = this.subscriptionIdentifier
    }
    if (Supercast.token) {
      headers['Authorization'] = `Bearer ${Supercast.token}`;
    }
    const response = await fetch(url, {
      headers,
      credentials: 'include'
    });

    if (response.ok) {
      this.success = true;
      const html = await response.text();
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.querySelector('.embed-content');
    } else {
      const error = document.createElement('div');
      error.style.color = "red";
      error.innerText = "We were unable to find your membership."
      return error;
    }
  }

  async fetchStyle() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    return styleEl;
  }

  attachEvents(shadow) {
    const overlay = shadow.querySelector('.overlay')
    const form = shadow.querySelector('form.user')
    const response = shadow.querySelector('.response')

    window.addEventListener('supercast-login-state-changed', () => {
      this.updateContent();
    });

    shadow.querySelectorAll('.modal-link').forEach(element => {
      element.addEventListener('click', async (event) => {
        overlay.classList.add("open")
        const player = shadow.querySelector('#user_player')
        const feedToken = shadow.querySelector('#user_feed_id')
        const qrContainer = shadow.querySelector('#qr_container')

        // fill in form
        player.value = event.currentTarget.dataset.player
        feedToken.value = event.currentTarget.dataset.id

        // fetch qr code
        generateQRCode(qrContainer, `${domain(this.subdomain, this.environment)}/players/${event.currentTarget.dataset.player}?feed_token=${event.currentTarget.dataset.id}`)
      });
    });

    shadow.querySelectorAll(".clipboard").forEach(element => {
      element.addEventListener("click", this.copyToClipboard.bind(this));
    });

    overlay.addEventListener('click', this.closeModal.bind(this));

    form.addEventListener('submit', (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);

      fetch(event.target.action, {
        method: "POST",
        body: formData,
        referrerPolicy: 'no-referrer'
      }).then((res) => {
        if ( res.status === 200 ) {
          response.classList.remove('hidden')
          setTimeout(() => {
            overlay.classList.remove("open");
            response.classList.add('hidden')
          }, 5000)
        }
      })
    });
  }

  closeModal(event) {
    const clicked = event.target;
    if (clicked.classList.contains("close") || clicked.closest(".modal") == null) {
      const overlay = this.shadow.querySelector('.overlay')
      overlay.classList.remove("open")
    }
  }

  copyToClipboard(event) {
    event.preventDefault();
    const element = this.shadow.querySelector(event.currentTarget.dataset.clipboardTarget);
    element.select();
    navigator.clipboard.writeText(element.value);
    event.target.textContent = "Copied!"
    setTimeout(() => {
      event.target.textContent = "Copy"
    }, 2000);
  }

  showError(message) {
    const error = document.createElement("div");
    error.style.color = "red";
    error.textContent = message;
    this.appendChild(error);
  }
}
