#credit_card {
  margin-top: 16px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #dde2e6;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  line-height: 16px;
  padding: 11px 12px 9px;
  transition: 0.2s all ease-out;
}
#credit_card:hover {
  border-color: #c0c9d0;
}
#credit_card:focus {
  border-color: #333a41;
  box-shadow: inset 0 0 0 1px #333a41;
  outline: none;
}

/*# sourceMappingURL=SupercastPurchase_local.css.map */
