.bundle {
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background-color: white;
}

.bundle-content {
  padding: 24px;
}

.bundle-cta {
  margin: 10px 0;
}

h2 {
  margin-top: 12px;
  margin-bottom: 24px;
  font-weight: 900;
}

.plan-pricing-trial {
  margin-bottom: 16px;
}

.button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  padding: 15px 20px 13px;
  text-decoration: none;
  transition: background-color 0.07s linear;
  color: #FFF;
  font-weight: 600;
  background-color: var(--supercast-button-background-color, #3d62b3);
}

.bundle-featured-image {
  flex: 0 0 200px;
}

.bundle-featured-image img {
  border-radius: 12px 12px 0 0;
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.button:hover {
  filter: brightness(1.1);
}

[style*="--aspect-ratio"]> :first-child {
  width: 100%;
}

[style*="--aspect-ratio"]>img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"]> :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.hidden {
  display: none;
}

.plan-pricing.with-promotion .standard-price {
  color: #848e97;
  text-decoration: line-through;
}

.plan-pricing.with-promotion .promotion-price {
  color: var(--accent-color);
}

.plan-pricing.with-promotion .duration-details {
  font-size: 12px;
  color: #848e97;
  font-weight: normal;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  color: var(--accent-color-text, #fff);
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  /* Needed for Safari */
  width: 100px;
  display: block;
  background: var(--supercast-button-background-color, #3d62b3);
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  filter: brightness(0.6);
  border-left: 3px solid var(--supercast-button-background-color, #3d62b3);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--supercast-button-background-color, #3d62b3);
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  filter: brightness(0.6);
  border-right: 3px solid var(--supercast-button-background-color, #3d62b3);
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--supercast-button-background-color, #3d62b3);
}