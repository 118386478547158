/* eslint-disable no-undef */
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import PriceComponentController from '../../components/price_component_controller';
import VideoPlayerController from '../../components/media/video_player_controller';
import AudioPlayerController from '../../components/media/audio_player_controller';

const application = Application.start()

// Whitelist the controllers we want to use to keep embed small
application.register("price-component", PriceComponentController);
application.register("media--video-player", VideoPlayerController);
application.register("media--audio-player", AudioPlayerController);
