// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastPurchase.css?raw';
import localStyles from './SupercastPurchase_local.css?raw';
import StripePayment from 'utils/stripe_payment';
import { extractScripts } from '../utils/injectDom';
import { domain } from 'utils/environment';

export default class SupercastPurchase extends HTMLElement {
  connectedCallback() {
    this.planId = this.attributes.plan_id && parseInt(this.attributes.plan_id.value, 10);
    this.subdomain = this.attributes.subdomain.value == "" ? this.attributes.subdomain.value : null;
    this.code = this.attributes.code && this.attributes.code.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    if (this.attributes.modal && this.attributes.modal.value == true) {
      this.updateContent();
    } else {
      window.location.href = `${domain(this.subdomain, this.environment)}/subscriptions/new?${this.planId > 0 ? `subscription[plan_id]=${this.planId}` : `code=${this.code}`}`;
    }
  }

  async updateContent() {
    const shadow = this.attachShadow({mode: 'open'});
    this.attachStyles(shadow);

    const cardContent = await this.fetchContent();

    window.initStripe = function(args) {
      // Note the override - this is important so we don't query against `docuemnt` (which won't find things in the shadow DOM)
      const stripePayment = new StripePayment({...args, rootElement: shadow });
      stripePayment.setup();
    }

    shadow.appendChild(cardContent);

    this.scripts.forEach(script => shadow.appendChild(script));

    // HACK: Copied from subscriptions/new - it's relying on document so can't target anything in
    // the shadow root. We can remove it when Turbo fixes the underlying bug, so just copying it here.
    shadow.querySelector('form.stripe-form').addEventListener('submit', (e) => {
      e.stopPropagation();
      e.target.submit();
    });

    this.attachEvents(shadow);

    window.supercast.trigger('pageload');
  }

  async fetchContent() {
    const url = new URL(`${domain(this.subdomain, this.environment)}/subscriptions/new`);
    if (this.planId > 0) {
      console.warn('Supercast warning - Loading plans by plan / subdomain is deprecated. We recommend loading your plans using an embed code. You can find your code by clicking "Embed this plan" from your dashboard.');
      url.searchParams.set('subscription[plan_id]', this.planId);
    } else {
      url.searchParams.set('code', this.code);
    }
    const response = await fetch(url);
    const html = await response.text();

    // Put the result inside a container so we can add an overlay, etc.
    const container = document.createElement('div');
    container.classList.add('modal-container');

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');

    const modal = document.createElement('div');
    modal.classList.add('modal');
    container.appendChild(overlay);
    overlay.appendChild(modal);

    const card = this.extractCard(html);

    // Hold the scripts for later, until everything is fully on the DOM (stripe will expect it to be there, so we
    // don't want to call initStripe too early)
    this.scripts = extractScripts(card);

    modal.appendChild(card);
    modal.appendChild(this.poweredBy());

    return container;
  }

  poweredBy() {
    const el = document.createElement('div');
    el.classList.add('powered-by');
    el.innerText = "Powered by Supercast";
    return el;
  }

  async attachStyles() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    this.shadowRoot.appendChild(styleEl);

    // For credit cards, we attach some local styles because credit cards are pulled out of the shadow root.
    const localStyleEl = document.createElement('style');
    localStyleEl.textContent = localStyles;
    this.appendChild(localStyleEl);

    return styleEl;
  }

  extractCard(html) {
    const holderEl = document.createElement('div');
    holderEl.innerHTML = html;
    const card = holderEl.querySelector('.modal-content');

    // Extract out stripe elements and convert them to slotted content. This is done b/c Stripe doesn't function properly
    // when its elements are in the Shadow DOM.
    card.querySelectorAll(".stripe-component").forEach(component => {
      const slot = document.createElement('slot');
      slot.name = "slot-" + component.id;
      component.slot = "slot-" + component.id;
      component.parentNode.replaceChild(slot, component);
      this.appendChild(component);
    });

    card.querySelectorAll("#recaptcha-container").forEach(component => {
      const slot = document.createElement('slot');
      slot.name = "slot-" + component.id;
      component.slot = "slot-" + component.id;
      component.parentNode.replaceChild(slot, component);
      this.appendChild(component);
    });

    return card;
  }

  attachEvents(shadow) {
    const overlay = shadow.querySelector('.overlay')
    shadow.querySelector('.overlay').addEventListener('click', (event) => {
      // Only consider direct events
      if (event.target == overlay) {
        this.parentNode.removeChild(this);
      }
    });

    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = "https://www.google.com/recaptcha/api.js?onload=supercastStripeSetup";
    shadow.appendChild(recaptchaScript);
  }
}