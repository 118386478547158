h2 {
  color: #333a41;
  flex: 1 0 0;
  font-size: 1em;
  line-height: 2em;
}

.response {
  transition: 0.5s;
  padding: 10px;
  background-color: #29c884;
  color: white;
  margin-top: 15px;
  border-radius: 16px;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
  justify-content: center;
}

.overlay {
  padding: 10px;
  display: none;
}

.overlay .close {
  position: fixed;
  color: #CCC;
  top: 10px;
  right: 20px;
  font-size: 1.5em;
  cursor: pointer;
  color: #f65f5a;
}

.overlay.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(79, 72, 72, 0.9) 0%, rgba(123, 123, 123, 0.9) 50%, rgba(225, 225, 225, 0.9) 100%);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes dropIn {
  from {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.modal {
  box-sizing: border-box;
  position: relative;
  border-radius: 16px;
  padding: 32px 48px 48px;
  overflow-y: auto;
  max-height: calc(100vh - 10vw);
  max-width: 40vw;
  opacity: 0;
  background-color: white;
  animation: dropIn 0.3s ease-out forwards;
  text-align: center;
}
@media (max-width: 500px) {
  .modal {
    padding: 32px 24px;
  }
}

#user_phone_number {
  padding: 17px;
  margin-right: 5px;
}

.sections-row {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
}
.sections-row:first-child {
  margin-top: -10px;
}
.sections-row:last-child {
  margin-bottom: -10px;
}

.section-col {
  border-radius: 10px;
  color: #333a41;
  line-height: 23px;
  margin: 10px;
}
.section-col.full {
  flex: 1 0 0;
}
.section-col.bg {
  background-color: #FFF;
}

.section-pad {
  padding: 20px;
}

.home__logo {
  border-radius: 10px;
  float: left;
  margin-right: 15px;
}

.home__channel-description {
  margin: 0;
}

.nested-field-container {
  border: 1px solid #e9edef;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 9px 12px;
}
.nested-field-container input {
  border: 0;
  flex-grow: 2;
  margin-right: 10px;
}

.home__players {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.home__player {
  flex: 100% 0 0;
}
@media (min-width: 768px) {
  .home__player {
    flex: 50% 0 0;
  }
}
@media (min-width: 1280px) {
  .home__player {
    flex: 25% 0 0;
  }
}
.home__player a {
  background: #f8f9fa;
  border-radius: 12px;
  color: inherit;
  display: block;
  font-weight: 500;
  margin: 5px;
  padding: 10px;
  text-decoration: none;
  transition: all 0.1s ease-in;
}
.home__player a:hover, .home__player a:focus {
  background: #eceff2;
  outline: none;
}
.home__player img {
  border-radius: 10px;
  height: 50px;
  margin-right: 5px;
  vertical-align: middle;
  width: 50px;
}

.home__social-icons {
  display: flex;
  flex-wrap: wrap;
}
.home__social-icons .home__social-icon {
  flex-grow: 1;
}
.home__social-icons .home__social-icon img {
  height: 50px;
  margin-right: 5px;
  vertical-align: middle;
  width: 50px;
}
.home__social-icons .home__social-icon a {
  background: #f8f9fa;
  border-radius: 12px;
  color: inherit;
  display: block;
  font-weight: 500;
  margin: 5px;
  padding: 10px;
  text-decoration: none;
  transition: all 0.1s ease-in;
}
.home__social-icons .home__social-icon a:hover, .home__social-icons .home__social-icon a:focus {
  background: #eceff2;
  outline: none;
}

.nested-field-container {
  border: 1px solid #e9edef;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 9px 12px;
}
.nested-field-container input {
  border: 0;
  flex-grow: 2;
  margin-right: 10px;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  padding: 15px 20px 13px;
  text-decoration: none;
  transition: background-color 0.07s linear;
}
.button:focus {
  outline: none;
}
.button.positive {
  color: #FFF;
  font-weight: 600;
  background-color: var(--supercast-button-background-color, #29c884);
}
.button.positive:hover, .button.positive:focus {
  filter: brightness(0.9);
}

/*# sourceMappingURL=SupercastPlayerLinks.css.map */
