

export default class Base extends HTMLElement {

  attributeConfig = {};

  connectedCallback() {
    if (this.parseAttributes()) {
      this.updateContent();
    }
  }

  parseAttributes() {
    const missingFields = [];

    for (const key in this.attributeConfig) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.attributes.hasOwnProperty(key)) {
        this[key] = this.attributes[key].value;
      } else if (this.attributeConfig[key].default !== null) {
        this[key] = this.attributes[key].default;
      }

      if (this.attributeConfig[key].required && this[key] === null) {
        missingFields.push(key);
      }
    }

    if (missingFields.length > 0) {
      this.showError("The following attributes are required but were not specified: " + missingFields.join(", "));
      return false;
    } else {
      return true;
    }
  }

  async updateContent() {
    const [cardContent, css] = await Promise.all([this.fetchCard(), this.fetchStyle()]);

    const shadow = this.attachShadow({mode: 'closed'});
    this.rootElement = shadow;
    shadow.appendChild(css);
    shadow.appendChild(cardContent);

    this.attachEvents(shadow);
  }

  /**
   * This should return an element representing the card content.
   */
  async fetchCard() {
    throw new Error("Not implemented");
  }

  /**
   * This should return an element representing the style.
   */
  async fetchStyle() {
    throw new Error("Not implemented");
  }

  showError(message) {
    this.shadowRoot.innerHTML = `
      <div style="color: red;">${message}</div>
    `
  }

  /**
   * This should attach any event listeners to the card content.
   */
  attachEvents() {
    // Optional to implement
  }
}