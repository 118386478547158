// Note: We use raw-loader here since we don't want the styles to be parsed into an object, we just want the actual css text
// so we can place it in the shadow dom.
import styles from './SupercastGoal.css?raw';
import { domain } from 'utils/environment';

export default class SupercastGoal extends HTMLElement {
  connectedCallback() {
    this.goalCode = this.attributes.code && this.attributes.code.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";

    if (!this.goalCode) {
      this.showError("Goal code was not provided.")
    }

    this.updateContent();
  }

  async updateContent() {
    const [content, css] = await Promise.all([this.fetchContent(), this.fetchStyle()]);

    const shadow = this.attachShadow({mode: 'closed'});
    shadow.appendChild(css);
    shadow.appendChild(content);

    this.attachEvents(shadow);
  }

  async fetchContent() {
    const url = new URL(`${domain(this.subdomain, this.environment)}/embeds/goals/${this.goalCode}`);
    const response = await fetch(url);
    if (response.ok) {
      const html = await response.text();
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.querySelector('.embed-content');
    } else {
      const error = document.createElement('div');
      error.style.color = "red";
      error.innerText = "We were unable to find your goal."
      return error;
    }
  }

  async fetchStyle() {
    const styleEl = document.createElement('style');
    styleEl.textContent = styles;
    return styleEl;
  }

  attachEvents() {
  }

  showError(message) {
    const error = document.createElement("div");
    error.style.color = "red";
    error.textContent = message;
    this.appendChild(error);
  }
}
