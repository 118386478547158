* {
  box-sizing: border-box;
}

.results-container {
  position: relative;
  color: #333a41;
}

input[type=text] {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
}
input[type=text].with-results {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
input[type=text]:focus {
  outline: none;
}
input[type=text]:focus-visible {
  outline: none;
}

a.result {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
}
a.result:hover {
  background-color: #f8f9fa;
}

strong {
  color: #22252a;
  font-weight: bold;
}

.subtitle {
  color: #848e97;
}

.description {
  color: #848e97;
  font-size: 12px;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-top: 5px;
}

.caret {
  color: #848e97;
  font-weight: 200;
}

ul {
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 24px;
  padding: 0;
  background: white;
  margin-top: 0;
  border: 1px solid #ccc;
  border-top: none;
}

li {
  display: block;
  border-bottom: solid 1px #e9edef;
}
li:last-child {
  border-bottom: none;
}
li .artwork-container {
  width: 64px;
  margin-right: 10px;
}
li img {
  height: 64px;
  width: 64px;
}

/*# sourceMappingURL=SupercastEntitySearch.css.map */
