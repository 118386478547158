/* eslint-disable no-undef */
import { domain } from 'utils/environment';

export default class SupercastPlayer extends HTMLElement {
  async connectedCallback() {
    this.itemId = parseInt(this.attributes["item-id"] && this.attributes["item-id"].value, 10);
    this.itemType = this.attributes["item-type"].value;
    this.subdomain = this.attributes.subdomain.value || window.Supercast.currentSubdomain;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";
    this.subscriptionIdentifier = this.attributes['subscription-identifier'] && this.attributes['subscription-identifier'].value;

    this.updateContent();
  }

  async updateContent() {
    const content = await this.fetchContent();

    const shadow = this.attachShadow({ mode: 'open' });
    shadow.innerHTML = '';
    shadow.appendChild(content);

    if (this.success) {
      this.attachEvents(shadow);
    }
  }

  async fetchContent() {
    const url = new URL(`${domain(this.subdomain, this.environment)}/embeds/player`);

    url.searchParams.set("id", this.itemId);
    url.searchParams.set("type", this.itemType);

    if (Supercast.currentFeedToken) {
      url.searchParams.set("key", Supercast.currentFeedToken);
    }
    const headers = {};
    if (this.subscriptionIdentifier) {
      headers['X-Supercast-Subscription-Identifier'] = this.subscriptionIdentifier
    }
    if (Supercast.token) {
      headers['Authorization'] = `Bearer ${Supercast.token}`;
    }
    const response = await fetch(url, {
      headers,
      credentials: 'include'
    });

    if (response.ok) {
      this.success = true;
      const html = await response.text();
      const div = document.createElement('div');
      div.innerHTML = html;

      let slotIndex = 0;

      div.querySelectorAll("[data-controller]").forEach(component => {
        const slot = document.createElement('slot');
        slot.name = "slot-" + slotIndex;
        component.slot = "slot-" + slotIndex;
        slotIndex += 1;
        component.parentNode.replaceChild(slot, component);
        this.appendChild(component);
      });
      return div.querySelector('.embed-content');
    } else {
      const error = document.createElement('div');
      error.style.color = "red";
      const errorText = await response.text();
      error.innerText = errorText;
      return error;
    }
  }

  attachEvents() {
  }
}
