/* eslint-disable no-undef */
import SupercastEmbed from '../elements/SupercastEmbed';
import SupercastPlan from '../elements/SupercastPlan';
import SupercastGoal from '../elements/SupercastGoal';
import SupercastPurchase from '../elements/SupercastPurchase';
import SupercastPlayerLinks from '../elements/SupercastPlayerLinks';
import SupercastGate from '../elements/SupercastGate';
import SupercastLogin from '../elements/SupercastLogin';
import SupercastEntitySearch from '../elements/SupercastEntitySearch';
import SupercastPlayer from '../elements/SupercastPlayer';

import { domain } from 'utils/environment';
import 'utils/supercast';
import "./embed_controllers";

customElements.define('supercast-embed', SupercastEmbed);
customElements.define('supercast-plan', SupercastPlan);
customElements.define('supercast-goal', SupercastGoal);
customElements.define('supercast-player-links', SupercastPlayerLinks);
customElements.define('supercast-purchase', SupercastPurchase);
customElements.define('supercast-gate', SupercastGate);
customElements.define('supercast-login', SupercastLogin);
customElements.define('supercast-entity-search', SupercastEntitySearch);
customElements.define('supercast-player', SupercastPlayer);

window.Supercast = {
  token: null,
  currentSubdomain: null,

  startPurchase: ({ code, subdomain, planId, environment = 'production', modal = false }) => {
    const purchaseEl = document.createElement('supercast-purchase');
    purchaseEl.setAttribute('code', code);
    purchaseEl.setAttribute('plan_id', planId);
    purchaseEl.setAttribute('subdomain', subdomain);
    purchaseEl.setAttribute('environment', environment);
    purchaseEl.setAttribute('modal', modal);

    document.body.appendChild(purchaseEl);
  },
  startLogin: ({ subdomain, environment = "production" } = {}) => {
    const loginEl = document.createElement('supercast-login');

    if (subdomain) { loginEl.setAttribute('subdomain', subdomain); }
    if (environment) { loginEl.setAttribute('environment', environment); }
    document.body.appendChild(loginEl);
  },
  track: async ({ subdomain, environment = "production" }) => {
    const query = new URLSearchParams(window.location.search);
    const url = new URL(`${domain(subdomain, environment)}/embeds/tracks`);

    // Pass through all utm properties
    if (query.has("utm_source")) {
        url.searchParams.set('utm_source', query.get('utm_source'));
    }
    if (query.has("utm_medium")) {
        url.searchParams.set('utm_medium', query.get('utm_medium'));
    }
    if (query.has("utm_term")) {
        url.searchParams.set('utm_term', query.get('utm_term'));
    }
    if (query.has("utm_content")) {
        url.searchParams.set('utm_content', query.get('utm_content'));
    }
    if (query.has("utm_campaign")) {
        url.searchParams.set('utm_campaign', query.get('utm_campaign'));
    }
    if (document.referrer) {
        url.searchParams.set('sc_ref', document.referrer);
    }

    const response = await fetch(url, { method: 'GET' }); 
    return response.ok;
  },
  requestCode: async ({ subdomain, email, environment = "production" }) => {
    const url = new URL(`${domain(subdomain, environment)}/embeds/user/code`);
    const data = new FormData();
    data.append("email", email);

    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: data
    });

    if (response.ok) {
        const { sent_to } = await response.json();
        return { success: true, sent_to: sent_to };
    } else {
        return { success: false };
    }
  },
  login: async ({ subdomain, email, code, environment = "production" }) => {
    const url = new URL(`${domain(subdomain, environment)}/embeds/user/verify_code`);
    const data = new FormData();
    data.append("email", email);
    data.append("code", code);

    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: data
    });

    if (response.ok) {
      const { user, token } = await response.json();
      Supercast.token = token
      document.cookie = `supercast_token=${Supercast.token};path=/;SameSite=None;Secure`;

      // Succesfully logged in, trigger an event so that other components can listen for it.
      window.dispatchEvent(new CustomEvent('supercast-login-state-changed', { detail: user }));
      return { success: true, user };
    } else {
      const errorDetails = await response.json();
      return { success: false, error: errorDetails.error };
    }
  },
  logout: async ({ subdomain, environment = "production" } = {}) => {
    const url = new URL(`${domain(subdomain, environment)}/embeds/user/logout`);

    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST'
    });

    if (response.ok) {
      Supercast.token = null;
      document.cookie = `supercast_token=;path=/;SameSite=None;Secure`;
      window.dispatchEvent(new CustomEvent('supercast-login-state-changed', null));
    }

    return response.ok;
  },
  currentUser: async ({ subdomain, environment = "production" } = {}) => {
    if (Supercast.token == null) {
      return null;
    }

    const url = new URL(`${domain(subdomain, environment)}/embeds/user`);
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${Supercast.token}`
      }
    });
    if (response.ok) {
      const json = await response.json();
      return json;
    } else if (response.status == 401) {
      return null;
    } else {
      throw new Error(`Error ${response.status} retrieving current Supercast user`);
    }
  },
  episodes: async ({ subdomain, environment = "production" } = {}) => {
    const user = await Supercast.currentUser({ subdomain, environment });
    if (user == null) {
      throw new Error("This user is not logged into Supercast. Episodes cannot be retrieved.");
    }
    subdomain = subdomain || Supercast.currentSubdomain;
    if (subdomain == null) {
      throw new Error("You did not provide a subdomain. Either pass one to the element or function you are using, or load embed.js from your subodmain (See https://supercast.readme.io/reference/javascript-api for more details).");
    }

    const subscription = user.subscriptions.find(s => s.subdomain == subdomain);
    if (subscription == null) {
      throw new Error(`Could not locate a subscription to ${subdomain} for ${user.email}`);
    }
    const response = await fetch(subscription.feed_url);
    if (response.ok) {
      const body = await response.text();
      const parser = new DOMParser();
      const feed = parser.parseFromString(body, "text/xml");
      return Array.from(feed.querySelectorAll("item")).map(ep => {
        return {
          id: parseInt(ep.querySelector('episode_id').textContent),
          title: ep.querySelector('title').textContent,
          description: ep.querySelector('description') && ep.querySelector('description').textContent,
          url: ep.querySelector('enclosure').getAttribute("url")
        }
      });
    } else {
      throw new Error(`Error ${response.status} retrieving episodes`);
    }
  },
  episode: async ({ subdomain, id, environment = "production" }) => {
    const episodes = await Supercast.episodes({ subdomain, environment });

    return episodes.find(e => e.id == id);
  },
  init: () => {
    const supercastCookie = document.cookie.split(";").find(c => c.trim().startsWith("supercast_token="));
    if (supercastCookie) {
      Supercast.token = supercastCookie.split("=")[1];
    }

    const currentScriptUrl = document.currentScript && new URL(document.currentScript.src);
    const subdomain = currentScriptUrl && currentScriptUrl.hostname.split(".").slice(0, -2).join(".");

    if (subdomain && subdomain != "app" && subdomain != "supercast") {
      Supercast.currentSubdomain = subdomain;
    } else {
      console.warn("You loaded Supercast embeds without using a subdomain. We recommended loading embed.js from (subdomain).supercast.com (See https://supercast.readme.io/reference/javascript-api for more details).");
    }

    if (window.location.protocol !== 'https:') {
      console.error('Supercast embeds will not work on non-secure pages. Please use HTTPS.');
    }

    document.body.attributes['data-supercast-embed-loaded'] = true;
  }
}

Supercast.init();
