export default class SupercastGate extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.subdomain = this.attributes.subdomain && this.attributes.subdomain.value;
    this.environment = (this.attributes.environment && this.attributes.environment.value) || "production";
    this.requiredPlans = (this.attributes['required-plans'] && this.attributes['required-plans'].value.split(",").map(p => parseInt(p)));
    this.shadow = this.attachShadow({ mode: 'closed' });

    this.updateContent();
  }

  async updateContent() {
    const [content] = await Promise.all([this.fetchContent()]);
    // Clear out the shadow root
    this.shadow.innerHTML = '';

    this.shadow.appendChild(content);

    this.attachEvents(this.shadow);
  }

  async fetchContent() {
    const currentUser = await window.Supercast.currentUser({ subdomain: this.subdomain, environment: this.environment });

    const gateStatus = this.gateStatus(currentUser);
    let template;
    if (gateStatus == "available") {
      template = this.availableTemplate();
    } else if (gateStatus == "plan_required") {
      template = this.planRequiredTemplate();
    } else {
      template = this.unavaialbleTemplate();
    }

    return template.content.cloneNode(true);
  }

  gateStatus(user) {
    if (user == null) {
      return "unavailable";
    } else if (this.requiredPlans && !this.requiredPlans.includes(user.plan_id)) {
      return "plan_required";
    } else {
      return "available";
    }
  }

  attachEvents() {
    window.addEventListener('supercast-login-state-changed', () => {
      this.updateContent();
    })
  }

  availableTemplate() {
    const template = document.createElement('template');
    template.innerHTML = `<div>
      <slot name="available"></slot>
    </div>`
    return template;
  }

  unavaialbleTemplate() {
    const template = document.createElement('template');
    template.innerHTML = `<div>
      <slot name="unavailable"></slot>
    </div>`
    return template;
  }

  planRequiredTemplate() {
    if (!this.querySelector("[slot='plan-required']")) {
      return this.unavaialbleTemplate();
    }
    const template = document.createElement('template');
    template.innerHTML = `<div>
      <slot name="plan-required"></slot>
    </div>`
    return template;
  }
}
